import { Button, Form, Input } from "antd";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import Logo from "../assets/images/svg/logo.svg";
import AppNotificationContainer from "../components/AppNotificationContainer";
import { ROUTES } from "../constants/commonConst";
import { RootState, useAppDispatch } from "../store";
import { login } from "../store/authSlice";
import { LoginDto } from "../util/dto/auth";
import IntlMessages from "../util/IntlMessages";
import { showErrorMessage } from "../components/Notification";

const SignIn = () => {
  // const {isLoading, error, userLogin} = useAuth();
  const { loading, error } = useSelector((state: RootState) => state.auth);
  const dispatch = useAppDispatch();
  const history = useHistory();

  const onFinishFailed = (errorInfo) => {
    console.log(errorInfo);
  };

  const onFinish = async (values: LoginDto) => {
    dispatch(login(values)).then((res) => {
      if (res?.payload?.access_token) {
        localStorage.setItem("token", res.payload.access_token);
        history.push(ROUTES.QUESTION_MANAGEMENT);
      } else {
      }
    });
  };

  return (
    <div className="gx-app-login-wrap">
      <div className="gx-app-login-header"></div>
      <div className="gx-app-login-container">
        <div className="gx-app-login-main-content">
          <img src={Logo} alt="" />
          <div className="gx-app-login-content">
            <Form
              initialValues={{ remember: true }}
              name="basic"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              className="gx-signin-form gx-form-row0"
            >
              <Form.Item
                rules={[
                  { required: true, message: "The input is not valid E-mail!" },
                ]}
                name="email"
              >
                <Input placeholder="Email" style={{fontSize: 16}} />
              </Form.Item>
              <Form.Item
                rules={[
                  { required: true, message: "Please input your Password!" },
                ]}
                name="password"
              >
                <Input type="password" placeholder="Password" style={{fontSize: 16}} />
              </Form.Item>
              <div className="mb-2">
                <Link to="/reset-password">
                  <IntlMessages id="app.userAuth.forgotPassword" />
                </Link>
              </div>
              <Form.Item>
                <Button type="primary" className="gx-mb-0" htmlType="submit">
                  <IntlMessages id="app.userAuth.signIn" />
                </Button>
              </Form.Item>
            </Form>
          </div>
          <AppNotificationContainer loading={loading} error={error} />
        </div>
      </div>
    </div>
  );
};

export default SignIn;
