import { Layout } from "antd";
import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";

import languageData from "./languageData";

import { useSelector } from "react-redux";
import { ROUTES, ROUTES_TITLE } from "../../constants/commonConst";
import {
  NAV_STYLE_DRAWER,
  NAV_STYLE_FIXED,
  NAV_STYLE_MINI_SIDEBAR,
  TAB_SIZE,
} from "../../constants/ThemeSetting";
import { RootState, useAppDispatch } from "../../store";
import {
  switchLanguage,
  toggleCollapsedSideNav,
} from "../../store/settingSlice";
import CustomScrollbars from "../../util/CustomScrollbars";
import UserProfile from "../Sidebar/UserProfile";
import { Socket, io } from "socket.io-client";
import moment from "moment";
import { updateIsNewMessage, updateIsReadMessage, updateIsUpdateGroupChat } from "../../store/socket/chatSupport.gateway";

const { Header } = Layout;

let socket: Socket;

const Topbar = () => {
  const { locale, navStyle, navCollapsed, width } = useSelector(
    (state: RootState) => state.setting
  );
  // const [searchText, setSearchText] = useState("");
  const [title, setTitle] = useState("");
  const dispatch = useAppDispatch();
  const history = useHistory();

  const token = localStorage.getItem("token");

  useEffect(() => {
    if (token) {
      socket = io(`${process.env.REACT_APP_DEV_SOCKET_URL}/notification`, {
        multiplex: false,
        transports: ["websocket", "polling"],
        auth: {
          token: `${token}`,
        },
        extraHeaders: {
          Authorization: `${token}`,
        },
      });

      socket.on("connect", () => {
        console.log(
          "SOCKET connect",
          socket?.connected,
          moment().format("HH:mm:ss")
        );
      });

      socket.on("disconnect", () => {
        console.log(
          "SOCKET disconnect",
          socket?.disconnected,
          moment().format("HH:mm:ss")
        );
      });

      socket?.on(`new_message`, (data) => {
        dispatch(
          updateIsNewMessage({
            hasNotification: true,
            data,
          })
        );
      });

      socket?.on(`read_message`, (data) => {
        dispatch(
          updateIsReadMessage({
            hasNotification: true,
            data,
          })
        );
      });

      socket?.on(`update_group_chat`, (data) => {
        dispatch(
          updateIsUpdateGroupChat({
            hasNotification: true,
            data,
          })
        );
      });

      return () => {
        socket?.disconnect();
      };
    }
  }, [token]);

  useEffect(() => {
    if (history.location.pathname === ROUTES.USER_MANAGEMENT) {
      setTitle(ROUTES_TITLE.USER_MANAGEMENT);
    } else if (history.location.pathname === ROUTES.ADMIN_MANAGEMENT) {
      setTitle(ROUTES_TITLE.ADMIN_MANAGEMENT);
    } else if (history.location.pathname === ROUTES.PERMISSION_MANAGEMENT) {
      setTitle(ROUTES_TITLE.PERMISSION_MANAGEMENT);
    } else if (history.location.pathname === ROUTES.QUESTION_MANAGEMENT) {
      setTitle(ROUTES_TITLE.QUESTION_MANAGEMENT);
    } else if (history.location.pathname === ROUTES.CATEGORY_MANAGEMENT) {
      setTitle(ROUTES_TITLE.CATEGORY_MANAGEMENT);
    } else if (history.location.pathname === ROUTES.NOTIFICATION_MANAGEMENT) {
      setTitle(ROUTES_TITLE.NOTIFICATION_MANAGEMENT);
    } else if (history.location.pathname === ROUTES.ANALYTIC_MANAGEMENT) {
      setTitle(ROUTES_TITLE.ANALYTIC_MANAGEMENT);
    } else if (history.location.pathname === ROUTES.REVENUE_MANAGEMENT) {
      setTitle(ROUTES_TITLE.REVENUE_MANAGEMENT);
    } else if (history.location.pathname === ROUTES.CHAT_SUPPORT) {
      setTitle(ROUTES_TITLE.CHAT_SUPPORT);

    } else if (history.location.pathname === ROUTES.USER_EBAY) {
      setTitle(ROUTES_TITLE.USER_EBAY);
    } else if (history.location.pathname === ROUTES.STATISTICS) {
      setTitle(ROUTES_TITLE.STATISTICS);
    } else if (history.location.pathname === ROUTES.CAMPAIGN) {
      setTitle(ROUTES_TITLE.CAMPAIGN);
    } else if (history.location.pathname === ROUTES.BBQ_EVENT) {
      setTitle(ROUTES_TITLE.BBQ_EVENT);
    }
  }, [history.location]);

  const languageMenu = () => (
    <CustomScrollbars className="gx-popover-lang-scroll">
      <ul className="gx-sub-popover">
        {languageData.map((language) => (
          <li
            className="gx-media gx-pointer"
            key={JSON.stringify(language)}
            onClick={() => dispatch(switchLanguage(language))}
          >
            <i className={`flag flag-24 gx-mr-2 flag-${language.icon}`} />
            <span className="gx-language-text">{language.name}</span>
          </li>
        ))}
      </ul>
    </CustomScrollbars>
  );

  // const updateSearchChatUser = (evt) => {
  //   setSearchText(evt.target.value);
  // };

  return (
    <Header className="header">
      {navStyle === NAV_STYLE_DRAWER ||
      ((navStyle === NAV_STYLE_FIXED || navStyle === NAV_STYLE_MINI_SIDEBAR) &&
        width < TAB_SIZE) ? (
        <div className="gx-linebar gx-mr-3">
          <i
            className="gx-icon-btn icon icon-menu"
            onClick={() => {
              dispatch(toggleCollapsedSideNav(!navCollapsed));
            }}
          />
        </div>
      ) : null}
      {/* <Link to="/" className="gx-d-block gx-d-lg-none gx-pointer">
        <img alt="" src={"/assets/images/w-logo.png"} />
      </Link> */}
      <h2 style={{ fontWeight: "bold", margin: "auto", fontSize: 32 }}>
        {title}
      </h2>

      <UserProfile />
    </Header>
  );
};

export default Topbar;
