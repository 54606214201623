import { Button, Form, Input } from "antd";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import AppNotificationContainer from "../components/AppNotificationContainer";
import { showErrorMessage } from "../components/Notification";
import { RootState, useAppDispatch } from "../store";
import { forgotPassword } from "../store/resetPasswordSlice";
import { LoginDto } from "../util/dto/auth";
import IntlMessages from "../util/IntlMessages";

const ForgotPassword = () => {
  // const {isLoading, error, userLogin} = useAuth();
  const { loading, error } = useSelector((state: RootState) => state.auth);
  const dispatch = useAppDispatch();
  const [step, setStep] = useState(1);

  const onFinishFailed = (errorInfo) => {
    console.log(errorInfo);
  };

  const onFinish = async (values: LoginDto) => {
    dispatch(forgotPassword(values)).then((res) => {
      if (res.payload) {
        setStep(2);
      } else {
        showErrorMessage("Sent Mail Error !!!");
      }
    });
  };

  return (
    <div className="gx-app-login-wrap">
      <div className="gx-app-login-header"></div>
      <div className="gx-app-login-container">
        <div className="gx-app-login-main-content flex-column">
          <div className="title">パスワード再設定</div>
          <div className="content-reset-password">
            {step === 1
              ? "An email will be sent to your....."
              : "An email has been sent to your....."}
          </div>
          {step === 1 && (
            <div className="gx-app-login-content">
              <Form
                initialValues={{ remember: true }}
                name="basic"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                className="gx-signin-form gx-form-row0"
              >
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: "The input is not valid E-mail!",
                    },
                  ]}
                  name="email"
                >
                  <Input placeholder="Email" />
                </Form.Item>
                <Form.Item>
                  <Button type="primary" className="gx-mb-0" htmlType="submit">
                    <IntlMessages id="app.userAuth.resetPassword" />
                  </Button>
                </Form.Item>
              </Form>
            </div>
          )}

          <AppNotificationContainer loading={loading} error={error} />
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
