import { useContext, useEffect } from "react";
import { useSelector } from "react-redux";
import { Route, Switch, useHistory } from "react-router-dom";
import { ROUTES } from "../constants/commonConst";
import { PermissionContext } from "../containers/App/MainApp";
import { RootState } from "../store";

import asyncComponent from "../util/asyncComponent";
// const { type } = useParams<any>();
const App = ({ match }) => {
  const { hiddenLeftTopBottomLayout } = useSelector(
    (state: RootState) => state.setting
  );
  const history = useHistory();
  const user = useContext(PermissionContext);

  useEffect(() => {
    init();
  }, []);

  const checkPermission = (listPermissions: [], permission: string) => {
    return listPermissions.some((element: any) => element.includes(permission));
  };

  const checkLinkPermission = () => {
    if (user) {
      const listPermissions = user.permissions.map((i: any) => i.name);
      if (!listPermissions.length) {
        return ROUTES.QUESTION_MANAGEMENT;
      }
      if (checkPermission(listPermissions, "User")) {
        return ROUTES.USER_MANAGEMENT;
      } else if (checkPermission(listPermissions, "Admin")) {
        return ROUTES.ADMIN_MANAGEMENT;
      } else if (checkPermission(listPermissions, "Question")) {
        return ROUTES.QUESTION_MANAGEMENT;
      } else if (checkPermission(listPermissions, "Notification")) {
        return ROUTES.NOTIFICATION_MANAGEMENT;
      } else if (checkPermission(listPermissions, "Statistical")) {
        return ROUTES.ANALYTIC_MANAGEMENT;
      } else if (checkPermission(listPermissions, "Profit")) {
        return ROUTES.REVENUE_MANAGEMENT;
      } else {
        return ROUTES.QUESTION_MANAGEMENT;
      }
    }
  };

  const init = () => {
    const token = localStorage.getItem("token");
    if (!token) {
      history.push(ROUTES.LOGIN);
    } else {
      history.push(checkLinkPermission() as string);
    }
  };
  return (
    <div
      className={!hiddenLeftTopBottomLayout ? "gx-main-content-wrapper" : ""}
      style={{ flex: 1 }}
    >
      <Switch>
        <Route
          path={`/sample`}
          component={asyncComponent(() => import("./SamplePage"))}
        />
        <Route
          exact
          path={`${ROUTES.USER_MANAGEMENT}`}
          component={asyncComponent(() => import("./UserManagement"))}
        />
        <Route
          exact
          path={`${ROUTES.ADMIN_MANAGEMENT}`}
          component={asyncComponent(() => import("./AdminManagement"))}
        />
        {/* <Route
          exact
          path={`${ROUTES.CREATE_ADMIN}`}
          component={asyncComponent(
            () => import("./AdminManagement/CreateAdmin")
          )}
        /> */}
        <Route
          exact
          path={`${ROUTES.EDIT_ADMIN}`}
          component={asyncComponent(
            () => import("./AdminManagement/EditAdmin")
          )}
        />
        {/* <Route
          exact
          path={`${ROUTES.CREATE_USER}`}
          component={asyncComponent(
            () => import("./UserManagement/CreateUser")
          )}
        /> */}
        <Route
          exact
          path={`${ROUTES.EDIT_USER}`}
          component={asyncComponent(() => import("./UserManagement/EditUser"))}
        />
        <Route
          exact
          path={`${ROUTES.REVENUE_MANAGEMENT}`}
          component={asyncComponent(() => import("./RevenueManagement"))}
        />
        <Route
          exact
          path={`${ROUTES.QUESTION_MANAGEMENT}`}
          component={asyncComponent(() => import("./QuestionManagement"))}
        />
        <Route
          exact
          path={`${ROUTES.CREATE_QUESTION}`}
          component={asyncComponent(
            () => import("./QuestionManagement/CreateQuestion")
          )}
        />
        <Route
          exact
          path={`${ROUTES.EDIT_QUESTION}`}
          component={asyncComponent(
            () => import("./QuestionManagement/EditQuestion")
          )}
        />
        <Route
          exact
          path={`${ROUTES.CATEGORY_MANAGEMENT}`}
          component={asyncComponent(() => import("./CategoryManagement"))}
        />
        <Route
          exact
          path={`${ROUTES.CREATE_CATEGORY}`}
          component={asyncComponent(
            () => import("./CategoryManagement/CreateCategory")
          )}
        />
        <Route
          exact
          path={`${ROUTES.EDIT_CATEGORY}`}
          component={asyncComponent(
            () => import("./CategoryManagement/EditCategory")
          )}
        />
        <Route
          exact
          path={`${ROUTES.ANALYTIC_MANAGEMENT}`}
          component={asyncComponent(() => import("./Analytics"))}
        />
        <Route
          exact
          path={`${ROUTES.REVENUE_MANAGEMENT}`}
          component={asyncComponent(() => import("./RevenueManagement"))}
        />
        <Route
          exact
          path={`${ROUTES.PERMISSION_MANAGEMENT}`}
          component={asyncComponent(() => import("./PermissionManagement"))}
        />
        <Route
          path={`${ROUTES.EDIT_PERMISSION}`}
          component={asyncComponent(
            () => import("./PermissionManagement/Edit")
          )}
        />
        <Route
          path={`${ROUTES.NOTIFICATION_MANAGEMENT}`}
          component={asyncComponent(() => import("./NotificationManagement"))}
        />
        <Route
          path={`${ROUTES.EDIT_NOTIFICATION}`}
          component={asyncComponent(
            () => import("./NotificationManagement/EditNotification")
          )}
        />
        <Route
          path={`${ROUTES.CREATE_NOTIFICATION}`}
          component={asyncComponent(
            () => import("./NotificationManagement/CreateNotification")
          )}
        />
        <Route
          path={`${ROUTES.CREATE_NOTIFICATION}`}
          component={asyncComponent(
            () => import("./NotificationManagement/EditNotification")
          )}
        />
        <Route
          path={`${ROUTES.CREATE_ADMIN}`}
          component={asyncComponent(
            () => import("./AdminManagement/CreateAdmin")
          )}
        />
        <Route
          exact
          path={`${ROUTES.REVENUE_MANAGEMENT}`}
          component={asyncComponent(() => import("./RevenueManagement"))}
        />
        <Route
          exact
          path={`${ROUTES.CHAT_SUPPORT}`}
          component={asyncComponent(() => import("./ChatSupport"))}
        />
        <Route
          exact
          path={`${ROUTES.CHANGE_PASSWORD}`}
          component={asyncComponent(() => import("./ChangePassword"))}
        />
        <Route
          exact
          path={`${ROUTES.STATISTICS}`}
          component={asyncComponent(() => import("./Statistics"))}
        />
        <Route
          exact
          path={`${ROUTES.USER_EBAY}`}
          component={asyncComponent(() => import("./UserEbayInnitDataSlow"))}
        />
        <Route
          exact
          path={`${ROUTES.CAMPAIGN}`}
          component={asyncComponent(() => import("./Campaign"))}
        />
        <Route
          exact
          path={`${ROUTES.CREATE_CAMPAIGN}`}
          component={asyncComponent(() => import("./Campaign/CreateCampaign"))}
        />
        <Route
          exact
          path={`${ROUTES.BBQ_EVENT}`}
          component={asyncComponent(() => import("./BbqEvent"))}
        />
        {/* <Route
          exact
          path={`${ROUTES.EDIT_CAMPAIGN}`}
          component={asyncComponent(() => import("./Campaign/EditCampaign"))}
        /> */}
      </Switch>
    </div>
  );
};

export default App;
