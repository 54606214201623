import { Button, Form, Input } from "antd";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import AppNotificationContainer from "../components/AppNotificationContainer";
import { RootState, useAppDispatch } from "../store";
import { resetPassword } from "../store/resetPasswordSlice";
import { LoginDto } from "../util/dto/auth";
import IntlMessages from "../util/IntlMessages";

const ResetPassword = () => {
  // const {isLoading, error, userLogin} = useAuth();
  const { loading, error } = useSelector((state: RootState) => state.auth);
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const [disbaleButton, setDisableButton] = useState(true);
  const [step, setStep] = useState(1);
  const location = useLocation();
  const history = useHistory();

  const onFinishFailed = (errorInfo) => {
    console.log(errorInfo);
  };

  const onFinish = async (values: LoginDto) => {
    const token = new URLSearchParams(location.search).toString().split("=")[1];
    dispatch(resetPassword({ password: values.password, token: token })).then(
      (res) => {
        if (res.payload) {
          if ((res.payload as any).data.affected === 1) {
            setStep(2);
            setTimeout(() => {
              history.push("/signin");
            }, 2000);
          }
        }
      }
    );
  };

  const onFieldsChange = () => {
    if (
      (!form.isFieldsTouched(true) ||
        !!form.getFieldsError().filter(({ errors }) => errors.length)
          .length) !== disbaleButton
    ) {
      setDisableButton(!disbaleButton);
    }
  };

  return (
    <div className="gx-app-login-wrap">
      <div className="gx-app-login-header"></div>
      <div className="gx-app-login-container">
        <div className="gx-app-login-main-content flex-column">
          <div className="title">Reset Password</div>
          <div className="content-reset-password">
            {step !== 1 && "Reset Password successfuly !!!"}
          </div>
          {step === 1 && (
            <div className="gx-app-login-content">
              <Form
                initialValues={{ remember: true }}
                form={form}
                name="basic"
                onFinish={onFinish}
                onFieldsChange={onFieldsChange}
                onFinishFailed={onFinishFailed}
                className="gx-signin-form gx-form-row0"
              >
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: "The input is not valid Password!",
                    },
                  ]}
                  name="password"
                >
                  <Input placeholder="New password" />
                </Form.Item>
                <Form.Item
                  dependencies={["password"]}
                  rules={[
                    {
                      required: true,
                      message: "The input is not valid Password!",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("password") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error("Not the same password")
                        );
                      },
                    }),
                  ]}
                  name="re-password"
                >
                  <Input placeholder="Confirm your new password" />
                </Form.Item>
                <Form.Item>
                  <Button
                    type="primary"
                    className="gx-mb-0"
                    htmlType="submit"
                    disabled={disbaleButton}
                  >
                    <IntlMessages id="app.userAuth.resetPassword" />
                  </Button>
                </Form.Item>
              </Form>
            </div>
          )}
          <AppNotificationContainer loading={loading} error={error} />
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
