import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { DEFAULT_PAGING } from "../constants/commonConst";
import { api } from "../util/api";

const initialState = {
  loading: false,
  error: "",
  message: "",
  actionResult: false,
  listCamGroup: [],
  pagination: DEFAULT_PAGING,
};

export const forgotPassword = createAsyncThunk(
  "resetPassword/forgotPassword",
  async (payload: { email: string }, { rejectWithValue }) => {
    try {
      const result = await api.patch("/forgot-password", payload);
      return result;
    } catch (e) {
      rejectWithValue(e);
    }
  }
);
export const resetPassword = createAsyncThunk(
  "/users/update-password-email",
  async (payload: { password: string; token: string }, { rejectWithValue }) => {
    try {
      if (localStorage.getItem("token")) {
        localStorage.removeItem("token");
        localStorage.setItem("token", payload.token);
      } else {
        localStorage.setItem("token", payload.token);
      }
      const result = await api.patch("/users/update-password-email", {
        password: payload.password,
      });
      localStorage.removeItem("token");
      return result;
    } catch (e) {
      rejectWithValue(e);
    }
  }
);

export const updateReset = createAsyncThunk(
  "resetPassword/updateReset",
  async (payload: { password: string; token: string }, { rejectWithValue }) => {
    try {
      let config = {};
      if (payload.token) {
        config = {
          headers: {
            Authorization: `Bearer ${payload.token}`,
          },
        };
      }
      return api.post("/forgot-password/reset", {
        password: payload,
        ...config,
      });
    } catch (e) {
      rejectWithValue(e);
    }
  }
);

export const resetPasswordSlice = createSlice({
  name: "resetPassword",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(forgotPassword.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(forgotPassword.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(forgotPassword.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.error.message || "";
      })
      .addCase(resetPassword.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(resetPassword.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(resetPassword.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.error.message || "";
      });
  },
});

export default resetPasswordSlice.reducer;
