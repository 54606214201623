import axios from "axios";

export const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL, //YOUR_API_URL HERE
  headers: {
    "Content-Type": "application/json",
  },
});

api.interceptors.request.use(async (req) => {
  let originalRequest = req;
  const token = localStorage.getItem("token");

  if (token) {
    originalRequest.headers.Authorization = "Bearer " + token;
    return Promise.resolve(originalRequest);
  }

  return req;
});

// Add a response interceptor
api.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response.status === 403) {
      window.location.href = "#/403";
    }
    if (error.response.status === 401) {
      window.location.href = "#/signin";
    }
    // return error;
    return Promise.reject(error);
  }
);

export const setBearerToken = (token: string) => {
  api.defaults.headers.common["Authorization"] = "Bearer " + token;
};
