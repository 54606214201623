import { AuthUser } from "../store/authSlice";

export const checkPermission = (
  authUser: AuthUser,
  ...premissionType: string[]
) => {
  if (authUser) {
    const permissionsArray = authUser?.permissions.map(
      (permission: { id: string; name: string }) => permission.name
    );
    if (
      authUser.isAdmin &&
      permissionsArray.some((per) => premissionType.includes(per))
    )
      return true;
    return false;
  }
};
