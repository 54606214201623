import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../index";

export interface ChatSupportSocketState {
  loading: boolean;
  isNewMessage: {
    data: any;
    hasNotification: boolean;
  };
  isReadMessage: {
    data: any;
    hasNotification: boolean;
  };
  isUpdateGroupChat: {
    data: any;
    hasNotification: boolean;
  };
}

const initialState: ChatSupportSocketState = {
  loading: false,
  isNewMessage: {
    data: undefined,
    hasNotification: false,
  },
  isReadMessage: {
    data: undefined,
    hasNotification: false,
  },
  isUpdateGroupChat: {
    data: undefined,
    hasNotification: false,
  },
};

const chatSupportSocketSlice = createSlice({
  name: "chatSupportSocket",
  initialState,
  reducers: {
    updateIsNewMessage: (state, action) => {
      state.isNewMessage = action.payload;
    },
    updateIsReadMessage: (state, action) => {
      state.isReadMessage = action.payload;
    },
    updateIsUpdateGroupChat: (state, action) => {
      state.isUpdateGroupChat = action.payload;
    },
  },
});

export const {
  updateIsNewMessage,
  updateIsReadMessage,
  updateIsUpdateGroupChat,
} = chatSupportSocketSlice.actions;

export const selectIsNewMessage = (state: RootState) =>
  state.chatSupportSocket.isNewMessage;

export const selectIsReadMessage = (state: RootState) =>
  state.chatSupportSocket.isReadMessage;

export const selectIsUpdateGroupChat = (state: RootState) =>
  state.chatSupportSocket.isUpdateGroupChat;

export default chatSupportSocketSlice.reducer;
