const languageData = [
  {
    languageId: 'english',
    locale: 'en',
    name: 'English',
    icon: 'us',
    key: "1",
  },
  {
    languageId: 'vietnamese',
    locale: 'vi',
    name: 'Việt Nam',
    icon: 'vn',
    key: "2",

  }
];
export default languageData;
