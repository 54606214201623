import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { api, setBearerToken } from "../util/api";
import { LoginDto } from "../util/dto/auth";
import { LocalKey, setLocalStore } from "../util/localStore";

export interface AuthState {
  loading: boolean;
  error: string;
  authUser?: AuthUser;
  loaded: boolean;
  token: string;
  trackingRoute: boolean;
}
export interface AuthUser {
  id: string;
  firstName: string;
  lastName: string;
  userFullname: string;
  isAdmin: boolean;
  isActive: boolean;
  isParent: boolean;
  email: string;
  permissions: [ { id : string , name : string}];
}

const initialState: AuthState = {
  loading: false,
  loaded: false,
  error: "",
  token: "",
  trackingRoute: false,
};

export const login = createAsyncThunk(
  "/login-admin",
  async (
    payload: LoginDto,
    { fulfillWithValue, rejectWithValue, dispatch }
  ) => {
    try {
      const response = await api
        .post("/login-admin", {
          ...payload,
        })
        .then((res) => {
          return res;
        });
      return response.data;
    } catch (error: any) {
      const errorObj = JSON.parse(error.request.response);
      return rejectWithValue(errorObj);
    }
  }
);

export const getProfile = createAsyncThunk(
  "/users/profile",
  async (_, {fulfillWithValue , rejectWithValue }) => {
    try {
      const response = await api.get("users/profile").then((res) => {return res});
      return response.data
    } catch (error: any) {
      const errorObj = JSON.parse(error.request.response);
      return rejectWithValue(errorObj);
    }
  }
);

export const me = createAsyncThunk(
  "auth/me",
  async (_, { fulfillWithValue, rejectWithValue }) => {
    try {
      const response = await api.get("auth/me");
      return response.data;
    } catch (error: any) {
      const errorObj = JSON.parse(error.request.response);
      return rejectWithValue(errorObj);
    }
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: (state) => {
      state.authUser = undefined;
      state.token = "";
      localStorage.removeItem("token");
    },
    setToken: (state, action) => {
      state.token = action.payload;
      setLocalStore(LocalKey.TOKEN, action.payload);
      setBearerToken(action.payload);
    },
    setTrackingRoute: (state, action) => {
      state.trackingRoute = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(login.fulfilled, (state, action) => {
        state.loading = false;
        state.token = action.payload;
        setBearerToken(action.payload);
      })
      .addCase(login.rejected, (state, action) => {
        console.log("action", _.get(action.payload, "message"));
        state.loading = false;
        state.error =
          _.get(action.payload, "message") === "USERNAME_IS_LOGIN"
            ? _.get(action.payload, "message") === "USERNAME_IS_STATUS"
              ? "Tài khoản không hoạt động "
              : "Tài khoản bị khóa"
            : "Tên đăng nhập hoặc mật khẩu không đúng";
      })
      .addCase(me.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(me.fulfilled, (state, action) => {
        state.loading = false;
        state.loaded = true;
        state.authUser = action.payload;
      })
      .addCase(me.rejected, (state) => {
        state.loaded = true;
        state.loading = false;
      })
  },
});

export const { logout, setToken, setTrackingRoute } = authSlice.actions;
export const selectAuth = (state: any) => state.auth;
export default authSlice.reducer;
