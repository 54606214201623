import { configureStore } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import authReducer from "./authSlice";
import settingReducer from "./settingSlice";
import adminReducer from "./adminSlice";
import userReducer from "./userSlice";
import planReducer from "./planSlice";
import chatSupportSocketReducer from "./socket/chatSupport.gateway";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    setting: settingReducer,
    admin: adminReducer,
    user: userReducer,
    plan: planReducer,
    chatSupportSocket: chatSupportSocketReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
