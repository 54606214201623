import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { DEFAULT_PAGING } from "../constants/commonConst";
import { api } from "../util/api";

const initialState = {
  loading: false,
  listPermissions : [],
  error: "",
  message: "",
  actionResult: false,
  listCamGroup: [],
  pagination: DEFAULT_PAGING,
};

export const getListChat = createAsyncThunk(
  "/api/group-chats",
  async (payload : { size : number; isSolved: boolean; } , { fulfillWithValue, rejectWithValue }) => {
    try {
      const response = await api.get("/group-chats", {params : payload});
      return response.data;
    } catch (error: any) {
      const errorObj = JSON.parse(error.request.response);
      return rejectWithValue(errorObj);
    }
  }
);
export const unreadMessage = createAsyncThunk(
  "/messages/unread-message",
  async (payload: { userId: string, groupChatId: string }, { fulfillWithValue, rejectWithValue }) => {
    try {
      const response = await api.post(`/messages/unread-message/${payload.userId}/${payload.groupChatId}`);
      return response.data;
    } catch (error: any) {
      const errorObj = JSON.parse(error.request.response);
      return rejectWithValue(errorObj);
    }
  }
);

export const getDetailChatGroup = createAsyncThunk(
  "/api/group-chats",
  async (payload: { size: number ,groupChatId : string }, { fulfillWithValue, rejectWithValue }) => {
    try {
      const response = await api.get(`/messages/group-chat/${payload.groupChatId}`, {params : {size : payload.size}});
      return response.data;
    } catch (error: any) {
      const errorObj = JSON.parse(error.request.response);
      return rejectWithValue(errorObj);
    }
  }
);


export const chatSupportSlice = createSlice({
  name: "chatSupportSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getListChat.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(getListChat.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(getListChat.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.error.message || "";
      })
      
  },
});

export const selectAdmin = (state: any) => state.admin;
export default chatSupportSlice.reducer;
