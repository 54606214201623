// import React from "react";
import { CameraOutlined } from "@ant-design/icons";
import {
  Avatar,
  Button,
  Col,
  Form,
  FormItemProps,
  Input,
  Modal,
  Popover,
  Row,
  Select,
  Upload,
} from "antd";
import ImgCrop from "antd-img-crop";
import React, { useState } from "react";
import { AiOutlineLogin } from "react-icons/ai";
import { BiSave } from "react-icons/bi";
import { RiCloseFill } from "react-icons/ri";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../store";
import { logout } from "../../store/authSlice";
import IntlMessages from "../../util/IntlMessages";

const UserProfile = () => {
  const [fileList, setFileList] = useState([]);
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const { authUser } = useSelector((state: RootState) => state.auth);
  const { Option } = Select;
  const [isModalUser, setIsModalUser] = useState(false);
  const [isModalPassWord, setIsModalPassWord] = useState(false);
  const [form] = Form.useForm();
  const onChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };
  const onLogoutClick = () => {
    dispatch(logout());
    window.location.reload();
  };
  const showModalPassWord = () => {
    setIsModalPassWord(true);
  };

  const showModalUser = () => {
    setIsModalUser(true);
  };
  const layout: FormItemProps = {
    labelCol: { span: 7 },
    wrapperCol: {
      span: 17,
    },
    labelAlign: "left",
  };

  const layout1: FormItemProps = {
    labelCol: { span: 5 },
    wrapperCol: {
      span: 20,
    },
    labelAlign: "left",
  };
  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    // imgWindow.document.write(image.outerHTML);
  };
  const userMenuOptions = (
    <ul className="gx-user-popover">
      {/* <li style={{ width: "190px", fontSize: "14px" }} onClick={showModalUser}>
        <BiUser className="BiUser" />
        {"Thông tin người dùng"}
        <IntlMessages id="sidebar.userProfile.userInformation" />
      </li>
      <li
        style={{ width: "190px", fontSize: "14px" }}
        onClick={showModalPassWord}
      >
        <RiKey2Fill className="RiKey2Fill" />
        {"Đổi mật khẩu"}
        <IntlMessages id="sidebar.userProfile.changePassword" />
      </li> */}
      <li style={{ width: "190px", fontSize: "14px" }} onClick={onLogoutClick}>
        <AiOutlineLogin className="AiOutlineLogin" />
        {/* {"Đăng xuất"} */}
        <IntlMessages id="sidebar.userProfile.logout" />
      </li>
    </ul>
  );

  return (
    <React.Fragment>
      <Modal
        title={<IntlMessages id="user.info.user" />}
        wrapClassName="customModalHeader"
        visible={isModalUser}
        width={"75%"}
        footer={null}
        onCancel={() => setIsModalUser(false)}
      >
        <Form form={form}>
          <Row>
            <Col
              style={{
                // width:"100%",
                textAlign: "center",
              }}
              span={24}
            >
              <Form.Item name={"StafftImg"}>
                <div>
                  <ImgCrop modalTitle={"Cập nhật ảnh"}>
                    <Upload
                      action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                      listType="picture-card"
                      onChange={onChange}
                      onPreview={onPreview}
                      className="csssmn"
                    >
                      {fileList.length < 1 && (
                        <CameraOutlined
                          style={{
                            fontSize: "30px",
                          }}
                        />
                      )}
                    </Upload>
                  </ImgCrop>
                </div>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xl={12}>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: <IntlMessages id="user.message.user" />,
                  },
                ]}
                {...layout}
                label={
                  <span>
                    <IntlMessages id="user.user.name" />
                    <span className="red">&nbsp;(*)</span>
                  </span>
                }
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xl={12}>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: <IntlMessages id="user.login.name" />,
                  },
                ]}
                {...layout}
                label={
                  <span>
                    <IntlMessages id="user.login.name" />
                    <span className="red">&nbsp;(*)</span>
                  </span>
                }
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col xl={12}>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: <IntlMessages id="user.message.bday" />,
                  },
                ]}
                {...layout}
                label={
                  <span>
                    <IntlMessages id="user.bday" />
                    <span className="red">&nbsp;(*)</span>
                  </span>
                }
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xl={12}>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: <IntlMessages id="user.message.company" />,
                  },
                ]}
                {...layout}
                label={
                  <span>
                    <IntlMessages id="user.company" />
                    <span className="red">&nbsp;(*)</span>
                  </span>
                }
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xl={12}>
              <Form.Item
                {...layout}
                rules={[
                  {
                    required: true,
                    message: <IntlMessages id="user.message.sx" />,
                  },
                ]}
                label={
                  <span>
                    <IntlMessages id="user.sx" />
                    <span className="red">&nbsp;(*)</span>
                  </span>
                }
              >
                <Select>
                  <Option>{"Nam"}</Option>
                  <Option>{"Nữ"}</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xl={12}>
              <Form.Item
                {...layout}
                rules={[
                  { required: true, message: <IntlMessages id="user.email" /> },
                ]}
                label={
                  <span>
                    <IntlMessages id="user.message.email" />
                    <span className="red">&nbsp;(*)</span>
                  </span>
                }
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xl={12}>
              <Form.Item {...layout} label={<IntlMessages id="user.dc" />}>
                <Input />
              </Form.Item>
            </Col>
            <Col xl={12}>
              <Form.Item {...layout} label={<IntlMessages id="user.phone" />}>
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xl={12}>
              <Form.Item
                {...layout}
                rules={[
                  {
                    required: true,
                    message: <IntlMessages id="user.position.message" />,
                  },
                ]}
                label={
                  <span>
                    <IntlMessages id="user.position" />
                    <span className="red">&nbsp;(*)</span>
                  </span>
                }
              >
                <Select>
                  <Option key={""}>{""}</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xl={12}>
              <Form.Item
                {...layout}
                rules={[
                  {
                    required: true,
                    message: <IntlMessages id="user.pb.message" />,
                  },
                ]}
                label={
                  <span>
                    <IntlMessages id="user.pb" />
                    <span className="red">&nbsp;(*)</span>
                  </span>
                }
              >
                <Select>
                  <Option>{""}</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col
              style={{
                textAlign: "right",
              }}
              span={24}
            >
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  <BiSave
                    style={{
                      marginTop: "10px",
                      marginRight: "3px",
                    }}
                  />
                  {<IntlMessages id="user.save" />}
                </Button>
                <Button>
                  <RiCloseFill
                    style={{
                      marginTop: "10px",
                      marginRight: "3px",
                    }}
                  />
                  {<IntlMessages id="user.Delete" />}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
      <Modal
        title={<IntlMessages id="user.Confirm.password" />}
        visible={isModalPassWord}
        width={"60%"}
        footer={null}
        onCancel={() => setIsModalPassWord(false)}
      >
        <Form>
          <Row>
            <Col xl={24}>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: <IntlMessages id="user.password.message" />,
                  },
                ]}
                {...layout1}
                label={
                  <span>
                    <IntlMessages id="user.passwordc" />
                    <span className="red">&nbsp;(*)</span>
                  </span>
                }
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xl={24}>
              <Form.Item
                rules={[
                  { required: true, message: "Bạn chưa nhập mật khẩu mới" },
                ]}
                {...layout1}
                label={
                  <span>
                    <IntlMessages id="Mật khẩu mới" />
                    <span className="red">&nbsp;(*)</span>
                  </span>
                }
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xl={24}>
              <Form.Item
                {...layout1}
                rules={[
                  { required: true, message: "Bạn chưa nhập lại mật khẩu" },
                ]}
                label={
                  <span>
                    <IntlMessages id="Nhập lại mật khẩu" />
                    <span className="red">&nbsp;(*)</span>
                  </span>
                }
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col
              style={{
                textAlign: "right",
              }}
              span={24}
            >
              <Button type="primary">
                <BiSave
                  style={{
                    marginTop: "10px",
                    marginRight: "3px",
                  }}
                />
                {<IntlMessages id="Lưu" />}
              </Button>
              <Button>{<IntlMessages id="Huỷ" />}</Button>
            </Col>
          </Row>
        </Form>
      </Modal>
      <div className="gx-flex-row gx-align-items-center gx-avatar-row">
        <Popover
          style={{
            width: "200px",
          }}
          placement="bottomRight"
          content={userMenuOptions}
          trigger="click"
        >
          <Avatar
            src={"https://via.placeholder.com/150"}
            className="gx-size-40 gx-pointer gx-mr-3"
            alt=""
          />
          <span className="gx-avatar-name">
            {authUser?.userFullname}
            <i className="icon icon-chevron-down gx-fs-xxs gx-ml-2" />
          </span>
        </Popover>
      </div>
    </React.Fragment>
  );
};

export default UserProfile;
