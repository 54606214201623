import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { DEFAULT_PAGING } from "../constants/commonConst";
import { api } from "../util/api";
import { CreateUserDto, UpdateUserDto } from "../util/dto/user.dto";

const initialState = {
  loading: false,
  error: "",
  message: "",
  actionResult: false,
  listCamGroup: [],
  pagination: DEFAULT_PAGING,
};

export const getUserStatistics = createAsyncThunk(
  "/users/user-statistics",
  async (
    _,
    { fulfillWithValue, rejectWithValue }
  ) => {
    try {
      const response = await api.get(`/users/user-statistics`);
      return response.data;
    } catch (error: any) {
      const errorObj = JSON.parse(error.request.response);
      return rejectWithValue(errorObj);
    }
  }
);

export const getListUser = createAsyncThunk(
  "/users/all-user",
  async (
    payload: { fullTextSearch: string, planId?: string },
    { fulfillWithValue, rejectWithValue }
  ) => {
    try {
      const response = await api.get(`/users/all-user`, {
        params: payload,
      });
      return response.data;
    } catch (error: any) {
      const errorObj = JSON.parse(error.request.response);
      return rejectWithValue(errorObj);
    }
  }
);

export const createUser = createAsyncThunk(
  "/users/create-user",
  async (payload: CreateUserDto, { rejectWithValue }) => {
    const result = await api
      .post(`/users`, { ...payload })
      .then((res) => res.data)
      .catch((e) => {
        rejectWithValue(e);
      });
    return result;
  }
);
export const editUser = createAsyncThunk(
  "/users/update-user",
  async (payload: UpdateUserDto, { rejectWithValue }) => {
    try {
      const result = await api
        .put(`/users`, { ...payload })
        .then((res) => res.data);
      return result;
    } catch (e) {
      console.log(e);
      rejectWithValue(e);
    }
  }
);
export const deleteUser = createAsyncThunk(
  "/users/delete-admin",
  async (payload: { id: string }, { rejectWithValue }) => {
    try {
      const result = await api
        .delete(`/users/${payload.id}`)
        .then((res) => res.data);
      return result;
    } catch (e) {
      console.log(e);
      rejectWithValue(e);
    }
  }
);

export const userSlice = createSlice({
  name: "userSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getListUser.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(getListUser.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(getListUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.error.message || "";
      })
      .addCase(getUserStatistics.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(getUserStatistics.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(getUserStatistics.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.error.message || "";
      })
      .addCase(createUser.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(createUser.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(createUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.error.message || "";
      })
      .addCase(editUser.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(editUser.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(editUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.error.message || "";
      })
      .addCase(deleteUser.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(deleteUser.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(deleteUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.error.message || "";
      });
  },
});

export default userSlice.reducer;
