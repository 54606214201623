import { Menu } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { ROUTES } from "../../constants/commonConst";
import {
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
} from "../../constants/ThemeSetting";
import { RootState, useAppDispatch } from "../../store";
import { checkPermission } from "../../util/auth";
import CustomScrollbars from "../../util/CustomScrollbars";
import { PermissionContext } from "../App/MainApp";
import AppsNavigation from "./AppsNavigation";
import SidebarLogo from "./SidebarLogo";
import { getListChat } from "../../store/chatSupportSlice";
import {
  selectIsNewMessage,
  selectIsReadMessage,
} from "../../store/socket/chatSupport.gateway";
import { toggleCollapsedSideNav } from "../../store/settingSlice";
import { getCountNumberEbaySyncing } from "../../store/userEbaySlice";

const SidebarContent = ({ sidebarCollapsed, setSidebarCollapsed }) => {
  const { themeType, navStyle } = useSelector(
    (state: RootState) => state.setting
  );
  const [totalUnreadMessage, setTotal] = useState(0);
  const [totalEbaySyncing, setTotalEbaySyncing] = useState(0);
  const location = useLocation();
  const [pathname, setPathname] = useState("");
  const history = useHistory();
  const user = useContext(PermissionContext);
  const dispatch = useAppDispatch();
  const isNewMessage = useSelector(selectIsNewMessage);
  const isReadMessage = useSelector(selectIsReadMessage);

  useEffect(() => {
    setPathname(location.pathname);
  }, [location.pathname]);

  //get data list user
  const getData = (isSolved: boolean) => {
    dispatch(getListChat({ size: 100, isSolved }))
      .then((res) => {
        if (res.payload) {
          if (res.payload.content.length === 0) {
            return;
          } else {
            const numberUnread = res.payload.content.reduce(
              (a, b) => a + b.messageUnRead,
              0
            );
            setTotal(numberUnread);
          }
        }
      })
      .catch((err) => {})
      .finally(() => {});
  };

  const getCountEbaySyncing = () => {
    dispatch(getCountNumberEbaySyncing()).then((res) => {
      setTotalEbaySyncing(res.payload?.data);
    });
  };

  useEffect(() => {
    getData(false);
    getCountEbaySyncing();
  }, []);

  useEffect(() => {
    if (isNewMessage?.data && isNewMessage.hasNotification) {
      getData(false);
    }
  }, [isNewMessage]);

  useEffect(() => {
    if (isReadMessage?.data && isReadMessage.hasNotification) {
      setTotal(isReadMessage.data.totalMessageUnRead);
    }
  }, [isReadMessage]);

  const getNoHeaderClass = (navStyle) => {
    if (
      navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR ||
      navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR
    ) {
      return "gx-no-header-notifications";
    }
    return "";
  };
  const selectedKeys = pathname.substr(1);
  const defaultOpenKeys = selectedKeys.split("/")[0];

  const menuItems = [
    {
      key: "question-management",
      className: `gx-menu-group word-break ${
        pathname === ROUTES.QUESTION_MANAGEMENT ? "background-color-gray" : ""
      }`,
      icon: (
        <i
          className={`icon icon-question-circle gx-text-primary`}
          style={{ marginRight: 15 }}
        ></i>
      ),
      onClick: () => {
        history.push(`${ROUTES.QUESTION_MANAGEMENT}`);
        setPathname(ROUTES.QUESTION_MANAGEMENT);
        dispatch(toggleCollapsedSideNav(false));
      },
      label: (
        <span
          className="gx-text-primary gx-font-weight-semi-bold"
          style={{ fontSize: "17px" }}
        >
          Question Management
        </span>
      ),
    },
    {
      key: "category-management",
      className: `gx-menu-group word-break ${
        pathname === ROUTES.CATEGORY_MANAGEMENT ? "background-color-gray" : ""
      }`,
      icon: (
        <i
          className={`icon icon-anchor gx-text-primary`}
          style={{ marginRight: 15 }}
        ></i>
      ),
      onClick: () => {
        history.push(`${ROUTES.CATEGORY_MANAGEMENT}`);
        setPathname(ROUTES.CATEGORY_MANAGEMENT);
        dispatch(toggleCollapsedSideNav(false));
      },
      label: (
        <span
          className="gx-text-primary gx-font-weight-semi-bold"
          style={{ fontSize: "17px" }}
        >
          Category Management
        </span>
      ),
    },
    {
      key: "user-management",
      className: `gx-menu-group word-break ${
        pathname === ROUTES.USER_MANAGEMENT && "background-color-gray"
      }
      ${
        !checkPermission(user, "Create User", "Edit User", "Delete User") &&
        "d-none"
      }
      `,
      icon: (
        <i
          className={`icon icon-user gx-text-primary`}
          style={{ marginRight: 15 }}
        ></i>
      ),
      onClick: () => {
        history.push(`${ROUTES.USER_MANAGEMENT}`);
        setPathname(ROUTES.USER_MANAGEMENT);
        dispatch(toggleCollapsedSideNav(false));
      },
      label: (
        <span
          className="gx-text-primary gx-font-weight-semi-bold"
          style={{ fontSize: "17px" }}
        >
          User Management
        </span>
      ),
    },
    {
      key: "user-ebay",
      className: `gx-menu-group word-break ${
        pathname === ROUTES.USER_EBAY && "background-color-gray"
      }`,
      icon: (
        <i
          className={`icon icon-user gx-text-primary`}
          style={{ marginRight: 15 }}
        ></i>
      ),
      onClick: () => {
        history.push(`${ROUTES.USER_EBAY}`);
        setPathname(ROUTES.USER_EBAY);
        dispatch(toggleCollapsedSideNav(false));
      },
      label: (
        <>
          <span
            className="gx-text-primary gx-font-weight-semi-bold"
            style={{ fontSize: "17px" }}
          >
            eBay account linkage control
          </span>
          {totalEbaySyncing > 0 && (
            <span className="number-unred gx-bg-primary gx-rounded-circle gx-badge gx-text-white">
              {totalEbaySyncing > 99 ? "99+" : totalEbaySyncing}
            </span>
          )}
        </>
      ),
    },
    {
      key: "admin-management",
      className: `gx-menu-group word-break ${
        pathname === ROUTES.ADMIN_MANAGEMENT ? "background-color-gray" : ""
      }
      ${
        !checkPermission(user, "Create Admin", "Edit Admin", "Delete Admin") &&
        "d-none"
      }`,
      icon: (
        <i
          className={`icon icon-calendar gx-text-primary`}
          style={{ marginRight: 15 }}
        ></i>
      ),
      onClick: () => {
        history.push(`${ROUTES.ADMIN_MANAGEMENT}`);
        setPathname(ROUTES.ADMIN_MANAGEMENT);
        dispatch(toggleCollapsedSideNav(false));
      },
      label: (
        <span
          className="gx-text-primary gx-font-weight-semi-bold"
          style={{ fontSize: "17px" }}
        >
          Admin Management
        </span>
      ),
    },
    {
      key: "notification-management",
      className: `gx-menu-group word-break ${
        pathname === ROUTES.NOTIFICATION_MANAGEMENT
          ? "background-color-gray"
          : ""
      } ${
        !checkPermission(
          user,
          "Create Notification",
          "Edit Notification",
          "Delete Notification"
        ) && "d-none"
      }
      `,
      icon: (
        <i
          className={`icon icon-tasks gx-text-primary`}
          style={{ marginRight: 15 }}
        ></i>
      ),
      onClick: () => {
        history.push(`${ROUTES.NOTIFICATION_MANAGEMENT}`);
        setPathname(ROUTES.NOTIFICATION_MANAGEMENT);
        dispatch(toggleCollapsedSideNav(false));
      },
      label: (
        <span
          className="gx-text-primary gx-font-weight-semi-bold"
          style={{ fontSize: "17px" }}
        >
          Notification Management
        </span>
      ),
    },
    {
      key: "chat-support",
      className: `gx-menu-group word-break ${
        pathname === ROUTES.CHAT_SUPPORT ? "background-color-gray" : ""
      }`,
      icon: (
        <i
          className={`icon icon-chat gx-text-primary`}
          style={{ marginRight: 15 }}
        ></i>
      ),
      onClick: () => {
        history.push(`${ROUTES.CHAT_SUPPORT}`);
        setPathname(ROUTES.CHAT_SUPPORT);
        dispatch(toggleCollapsedSideNav(false));
      },
      label: (
        <>
          <span
            className="gx-text-primary gx-font-weight-semi-bold"
            style={{ fontSize: "17px" }}
          >
            Chat Support
          </span>
          {totalUnreadMessage > 0 && (
            <span className="number-unred gx-bg-primary gx-rounded-circle gx-badge gx-text-white">
              {totalUnreadMessage > 99 ? "99+" : totalUnreadMessage}
            </span>
          )}
        </>
      ),
    },
    {
      key: "revenue-management",
      className: `gx-menu-group word-break ${
        pathname === ROUTES.REVENUE_MANAGEMENT ? "background-color-gray" : ""
      }
      ${
        !checkPermission(
          user,
          "Create Profit",
          "Edit Profit",
          "Delete Profit"
        ) && "d-none"
      }`,
      icon: (
        <i
          className={`icon icon-widgets gx-text-primary`}
          style={{ marginRight: 15 }}
        ></i>
      ),
      onClick: () => {
        history.push(`${ROUTES.REVENUE_MANAGEMENT}`);
        setPathname(ROUTES.REVENUE_MANAGEMENT);
        dispatch(toggleCollapsedSideNav(false));
      },
      label: (
        <span
          className="gx-text-primary gx-font-weight-semi-bold"
          style={{ fontSize: "17px" }}
        >
          Revenue Management
        </span>
      ),
    },
    {
      key: "statistics",
      className: `gx-menu-group word-break ${
        pathname === ROUTES.STATISTICS ? "background-color-gray" : ""
      }
      ${
        !checkPermission(
          user,
          "Create Profit",
          "Edit Profit",
          "Delete Profit"
        ) && "d-none"
      }`,
      icon: (
        <i
          className={`icon icon-widgets gx-text-primary`}
          style={{ marginRight: 15 }}
        ></i>
      ),
      onClick: () => {
        history.push(`${ROUTES.STATISTICS}`);
        setPathname(ROUTES.STATISTICS);
        dispatch(toggleCollapsedSideNav(false));
      },
      label: (
        <span
          className="gx-text-primary gx-font-weight-semi-bold"
          style={{ fontSize: "17px" }}
        >
          Statistics
        </span>
      ),
    },
    {
      key: "campaign",
      className: `gx-menu-group word-break ${
        pathname === ROUTES.CAMPAIGN ? "background-color-gray" : ""
      }`,
      icon: (
        <i
          className={`icon icon-setting gx-text-primary`}
          style={{ marginRight: 15 }}
        ></i>
      ),
      onClick: () => {
        history.push(`${ROUTES.CAMPAIGN}`);
        setPathname(ROUTES.CAMPAIGN);
        dispatch(toggleCollapsedSideNav(false));
      },
      label: (
        <span
          className="gx-text-primary gx-font-weight-semi-bold"
          style={{ fontSize: "17px" }}
        >
          Campaign
        </span>
      ),
    },
    {
      key: "bbqEvent",
      className: `gx-menu-group word-break ${
        pathname === ROUTES.BBQ_EVENT ? "background-color-gray" : ""
      }`,
      icon: (
        <i
          className={`icon icon-setting gx-text-primary`}
          style={{ marginRight: 15 }}
        ></i>
      ),
      onClick: () => {
        history.push(`${ROUTES.BBQ_EVENT}`);
        setPathname(ROUTES.BBQ_EVENT);
        dispatch(toggleCollapsedSideNav(false));
      },
      label: (
        <span
          className="gx-text-primary gx-font-weight-semi-bold"
          style={{ fontSize: "17px" }}
        >
          Bbq Event
        </span>
      ),
    },
  ];

  return (
    <>
      <SidebarLogo
        sidebarCollapsed={sidebarCollapsed}
        setSidebarCollapsed={setSidebarCollapsed}
      />

      <div className="gx-sidebar-content">
        <div
          className={`gx-sidebar-notifications ${getNoHeaderClass(navStyle)}`}
        >
          {/* <UserProfile /> */}
          <AppsNavigation />
        </div>
        <CustomScrollbars className="gx-layout-sider-scrollbar sidebar">
          <Menu
            defaultOpenKeys={[defaultOpenKeys]}
            selectedKeys={[selectedKeys]}
            theme={"light"}
            mode="inline"
            items={menuItems}
          ></Menu>
        </CustomScrollbars>
      </div>
    </>
  );
};

export default React.memo(SidebarContent);
