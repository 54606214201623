import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { DEFAULT_PAGING } from "../constants/commonConst";
import { api } from "../util/api";
import { CreateUserDto, UpdateUserDto } from "../util/dto/user.dto";

const initialState = {
  loading: false,
  listPermissions : [],
  error: "",
  message: "",
  actionResult: false,
  listCamGroup: [],
  pagination: DEFAULT_PAGING,
};

export const getListAdmin = createAsyncThunk(
  "/users/all-admin",
  async (payload: { fullTextSearch: string }, { fulfillWithValue, rejectWithValue }) => {
    try {
      const response = await api.get("/users/all-admin", {params : payload});
      return response.data;
    } catch (error: any) {
      const errorObj = JSON.parse(error.request.response);
      return rejectWithValue(errorObj);
    }
  }
);

export const getListRole = createAsyncThunk(
  "/roles/",
  async (payload: { id: string }, { rejectWithValue }) => {
    try {
      const result = await api.get(`/roles/${payload}`).then((res) => res.data);
      return result;
    } catch (e) {
      console.log(e);
      rejectWithValue(e);
    }
  }
);

export const updateRoles = createAsyncThunk(
  "/roles/create-role",
  async (payload: any, { rejectWithValue }) => {
    try {
      const result = await api
        .post(`/roles/create-role`, { ...payload })
        .then((res) => res.data);
      return result;
    } catch (e) {
      console.log(e);
      rejectWithValue(e);
    }
  }
);

export const createAdmin = createAsyncThunk(
  "/users/create-admin",
  async (payload: CreateUserDto, { rejectWithValue }) => {
    try {
      const result = await api
        .post(`/users/create-admin`, { ...payload })
        .then((res) => res.data);
      return result;
    } catch (e) {
      console.log(e);
      rejectWithValue(e);
    }
  }
);
export const editAdmin = createAsyncThunk(
  "/users/update-admin",
  async (payload: UpdateUserDto, { rejectWithValue }) => {
    try {
      const result = await api
        .put(`/users/update-admin`, { ...payload })
        .then((res) => res.data);
      return result;
    } catch (e) {
      console.log(e);
      rejectWithValue(e);
    }
  }
);
export const deleteAdmin = createAsyncThunk(
  "/users/delete-admin",
  async (payload: { id: string }, { rejectWithValue }) => {
    try {
      const result = await api
        .delete(`/users/delete-admin/${payload.id}`)
        .then((res) => res.data);
      return result;
    } catch (e) {
      console.log(e);
      rejectWithValue(e);
    }
  }
);

export const getDetailAdmin = createAsyncThunk(
  `/users/user/:id`,
  async (payload: { id: string }, { rejectWithValue }) => {
    try {
      const result = await api
        .get(`/users/${payload.id}`)
        .then((res) => res.data);
      return result;
    } catch (e) {
      console.log(e);
      rejectWithValue(e);
    }
  }
);

export const adminSlice = createSlice({
  name: "adminSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getListAdmin.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(getListAdmin.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(getListAdmin.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.error.message || "";
      })
      .addCase(createAdmin.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(createAdmin.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(createAdmin.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.error.message || "";
      })
      .addCase(deleteAdmin.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(deleteAdmin.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(deleteAdmin.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.error.message || "";
      })
      .addCase(getListRole.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(getListRole.fulfilled, (state, action) => {
        state.loading = false;
        // state.listPermissions = 
      })
      .addCase(getListRole.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.error.message || "";
      })
      .addCase(getDetailAdmin.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(getDetailAdmin.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(getDetailAdmin.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.error.message || "";
      });
  },
});

export const selectAdmin = (state: any) => state.admin;
export default adminSlice.reducer;
