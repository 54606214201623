/* eslint-disable */
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  LAYOUT_TYPE_FULL,
  NAV_STYLE_FIXED,
  THEME_TYPE_LITE,
  THEME_TYPE_SEMI_DARK,
} from "../constants/ThemeSetting";
import { api } from "../util/api";

export interface SettingState {
  navStyle: string;
  layoutType: string;
  themeType: string;
  themeColor: string;
  isDirectionRTL: boolean;
  locale: {
    languageId: string;
    locale: string;
    name: string;
    icon: string;
  };
  initURL: string;
  navCollapsed: boolean;
  width: number;
  pathname: string;
  hiddenLeftTopBottomLayout: boolean;
}

const initialState: SettingState = {
  navStyle: NAV_STYLE_FIXED,
  layoutType: LAYOUT_TYPE_FULL,
  themeType: THEME_TYPE_LITE,
  themeColor: "",
  isDirectionRTL: false,
  locale: {
    languageId: "vietnamese",
    locale: "vi",
    name: "Việt Nam",
    icon: "vn",
  },
  initURL: "",
  navCollapsed: false,
  width: window.innerWidth,
  pathname: "/",
  hiddenLeftTopBottomLayout: false,
};

export const settingSlice = createSlice({
  name: "setting",
  initialState,
  reducers: {
    changePathname: (state, action: PayloadAction<string>) => {
      state.pathname = action.payload;
    },
    toggleCollapsedSideNav: (state, action: PayloadAction<boolean>) => {
      state.navCollapsed = action.payload;
    },
    setThemeType: (state, action: PayloadAction<string>) => {
      state.themeType = action.payload;
    },
    setThemeColor: (state, action: PayloadAction<string>) => {
      state.themeColor = action.payload;
    },
    setDirectionRTL: (state, action: PayloadAction<boolean>) => {
      state.isDirectionRTL = action.payload;
    },
    onNavStyleChange: (state, action: PayloadAction<string>) => {
      state.navStyle = action.payload;
    },
    onLayoutTypeChange: (state, action: PayloadAction<string>) => {
      state.layoutType = action.payload;
    },
    switchLanguage: (state, action) => {
      state.locale = action.payload;
    },
    setInitUrl: (state, action: PayloadAction<string>) => {
      state.initURL = action.payload;
    },
    updateWindowWidth: (state, action: PayloadAction<number>) => {
      state.width = action.payload;
    },
    // setInitUrl: (state, action: PayloadAction<string>) => {
    //   state.initURL = action.payload;
    // },
    // setInitUrl: (state, action: PayloadAction<string>) => {
    //   state.initURL = action.payload;
    // }
    changeHiddenLeftTopBottomLayout: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.hiddenLeftTopBottomLayout = action.payload;
    },
  },
});

export const {
  toggleCollapsedSideNav,
  setThemeType,
  setThemeColor,
  setDirectionRTL,
  onLayoutTypeChange,
  switchLanguage,
  setInitUrl,
  changePathname,
  onNavStyleChange,
  changeHiddenLeftTopBottomLayout,
} = settingSlice.actions;

export default settingSlice.reducer;
