import { ConfigProvider } from "antd";
import { useEffect } from "react";
import { IntlProvider } from "react-intl";
import { useSelector } from "react-redux";
import {
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import CircularProgress from "../../components/CircularProgress";
import { ROUTES } from "../../constants/commonConst";
import AppLocale from "../../lngProvider";
import { RootState } from "../../store";
import ForgotPassword from "../ForgotPassword";
import ResetPassword from "../ResetPassword";
import SignIn from "../SignIn";
import MainApp from "./MainApp";

const RestrictedRoute = ({
  component: Component,
  location,
  authUser,
  ...rest
}) => <Route {...rest} render={(props) => <Component {...props} />} />;

const App = () => {
  const { locale, navStyle, layoutType, themeType, isDirectionRTL, initURL } =
    useSelector((state: RootState) => state.setting);
  const {
    authUser,
    loaded,
    loading: isLoadingUser,
  } = useSelector((state: RootState) => state.auth);

  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch();

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    const token = localStorage.getItem("token");
    if (!token) {
      history.push(ROUTES.LOGIN);
    } else {
      // history.push(ROUTES.USER_MANAGEMENT);
    }
  };

  const currentAppLocale = AppLocale[locale.locale];

  return (
    <ConfigProvider
      locale={currentAppLocale.antd}
      direction={isDirectionRTL ? "rtl" : "ltr"}
    >
      <IntlProvider
        locale={currentAppLocale.locale}
        messages={currentAppLocale.messages}
      >
        <Switch>
          <Route exact path="/signin" component={SignIn} />
          {/* <Route exact path="/403" component={NoPermission} /> */}
          <Route exact path="/reset-password" component={ForgotPassword} />
          <Route exact path="/forgot-password" component={ResetPassword} />
          <RestrictedRoute
            path={`${match.url}`}
            authUser={authUser}
            location={location}
            component={MainApp}
          />
        </Switch>
      </IntlProvider>
    </ConfigProvider>
  );
};

export default App;
