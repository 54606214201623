import React from "react";
import CircularProgress from "../CircularProgress";
import { message as toastNotification } from "antd";
import Auxiliary from "../../util/Auxiliary";
import { useIntl } from "react-intl";

interface IAppNotificationContainerProps {
  loading?: boolean;
  error?: string;
  message?: string;
  success?: string;
}

const AppNotificationContainer = ({
  loading,
  error,
  message,
  success,
}: IAppNotificationContainerProps) => {
  const intl = useIntl();
  return (
    <Auxiliary>
      {/* {loading && (
        <div className="gx-loader-view gx-loader-position">
          <CircularProgress />
        </div>
      )} */}
      {error &&
        toastNotification.error(
          <span id="message-id">{intl.formatMessage({ id: error })}</span>
        )}
      {message &&
        toastNotification.info(
          <span id="message-id">{intl.formatMessage({ id: message })}</span>
        )}
      {success &&
        toastNotification.success(
          <span id="message-id">{intl.formatMessage({ id: success })}</span>
        )}
    </Auxiliary>
  );
};

export default AppNotificationContainer;
