import Cookies from "universal-cookie";

export enum LocalKey {
  TOKEN = 'token',
  LOCAL_KEY_CONFIG = 'matrix_config',
  LOCAL_KEY_PLAYBACK_CONFIG = 'playback_config',
  LOCAL_KEY_VDS_CONFIG = 'vds_config'
}

export const setLocalStore = (key: LocalKey, value: any) => {
  try {
    localStorage.setItem(key, value)
  } catch (e) {
    const cookies = new Cookies();
    cookies.set(key, encodeURIComponent(value), { path: '/' });
  }
}

export const getLocalStore = (key: LocalKey) => {
  try {
    return localStorage.getItem(key)
  } catch (e) {
    const cookies = new Cookies();
    const result = decodeURIComponent(cookies.get(key));

    if (result && result !== 'undefined') {
      return result;
    } else {
      return undefined;
    }
  }
}

export const removeLocalStore = (key: LocalKey) => {
  try {
    localStorage.removeItem(key)
  } catch (e) {
    const cookies = new Cookies();
    cookies.remove(key);
  }
}
