import { TablePaginationConfig } from "antd";
import { BaseButtonProps } from "antd/lib/button/button";
import { AbstractTooltipProps } from "antd/lib/tooltip";

export const PAGE_SIZE = 20;
export const PAGE_SIZE_GET_ALL = 9999999;
export const VMS_VALUE = 2;
export const LCS_VALUE = 1;
export const TABLE_SETTING: TablePaginationConfig = {
  position: ["bottomRight"],
  hideOnSinglePage: true,
  size: "small",
  pageSize: PAGE_SIZE,
  disabled: false,
};
export const DEFAULT_FORM_ITEM = {
  bordered: false,
};
export const DEFAULT_PAGING = {
  page: 0,
  size: 0,
  total: 0,
};
export const RECORD_TIME_CODE = "sys-param-record-time";
export const RECORD_TIME_CHECK = "sys-param-approve-time";
export const SYS_PARAM_CONTROL = "sys-param-control";
export const SYS_PARAM_LIMIT_BOX_LIVE = "sys-param-limit-box-live";
export const DURATION_SHOW_MEMO_CODE = "sys-param-duration-show-memo";

export const HLS_DOMAIN = `${process.env.REACT_APP_API_URL}/hls/`;
export const FLV_DOMAIN = `${process.env.REACT_APP_API_URL}/videos/flv/`;

export const DATE_FORMAT_DD_MM_YYYY = "DD-MM-YYYY";
export const DATE_FORMAT_YYYY_MM_DD = "YYYY-MM-DD";
export const DATE_TIME_FORMAT_DD_MM_YYYY = "DD-MM-YYYY HH:mm";
export const DATE_TIME_FORMAT_DD_MM_YYYY_HH_MM_SS = "DD-MM-YYYY HH:mm:ss";
export const DATE_TIME_FORMAT_YYYY_MM_DD_HH_MM_SS = "YYYY-MM-DD HH:mm:ss";

export const TIME_FORMAT_HH_MM_SS = "HH:mm:ss";

export const BUTTON_ICON_ACTION_LIST: BaseButtonProps = {
  type: "default",
  className: "gx-mb-0",
};
export const BUTTON_PRIMARY: BaseButtonProps = {
  type: "primary",
  className: "gx-mb-0",
};
export const BUTTON_SUBMIT_FORM: BaseButtonProps = {
  type: "default",
  className:
    "gx-btn gx-text-white gx-mb-0 gx-background-primary gx-fontsize-24",
};

export const TOOLTIP_TOP_BLACK: AbstractTooltipProps = {
  placement: "top",
  color: "black",
  zIndex: 9999999999,
};

export const ROUTES = {
  LOGIN: "/signin",
  RESET_PASSWORD: "/admin/reset-password/:id",
  TRANSFER_MANAGEMENT: "/transfer-management",
  PERMISSION_MANAGEMENT: "/permission-management",
  EDIT_PERMISSION: "/permission-management/edit/:id",
  REVENUE_MANAGEMENT: "/revenue-management",
  USER_MANAGEMENT: "/user-management",
  ADMIN_MANAGEMENT: "/admin-management",
  CREATE_ADMIN: "/admin/create",
  EDIT_ADMIN: "/admin/edit/:id",
  CREATE_USER: "/user/create",
  EDIT_USER: "/user/edit/:id",
  NOTIFICATION_MANAGEMENT: "/notification-management",
  CREATE_NOTIFICATION: "/notification/create",
  EDIT_NOTIFICATION: "/notification/edit/:id",
  PLAN_SETTING: "/plan-setting",
  QUESTION_MANAGEMENT: "/question-management",
  CREATE_QUESTION: "/question/create",
  EDIT_QUESTION: "/question/edit/:id",
  CATEGORY_MANAGEMENT: "/category-management",
  CREATE_CATEGORY: "/category/create",
  EDIT_CATEGORY: "/category/edit/:id",
  ANALYTIC_MANAGEMENT: "/analytic-management",
  CHAT_SUPPORT: "/chat-support",
  CHANGE_PASSWORD: "/change-password",
  USER_EBAY: "/user-ebay",
  STATISTICS: "/statistics",
  CAMPAIGN: "/campaign",
  CREATE_CAMPAIGN: "/campaign/create",
  EDIT_CAMPAIGN: "/campaign/edit/:id",
  BBQ_EVENT: '/bbq-event'
};

export const ROUTES_TITLE = {
  TRANSFER_MANAGEMENT: "振り込み済みのユーザーリスト",
  REVENUE_MANAGEMENT: "Revenue Management",
  USER_EBAY: "eBay account linkage control",
  ADMIN_MANAGEMENT: "Admin Management",
  PERMISSION_MANAGEMENT: "Permissions Management",
  EDIT_PERMISSION: "Edit Permissions",
  USER_MANAGEMENT: "User Management",
  NOTIFICATION_MANAGEMENT: "Notification Management",
  QUESTION_MANAGEMENT: "Question Management",
  CATEGORY_MANAGEMENT: "Category Management",
  ANALYTIC_MANAGEMENT: "Analytic Management",
  CHAT_SUPPORT: "Chat support",
  PLAN_SETTING: "プラン設定",
  CHANGE_PASSWORD: "パスワード変更",
  STATISTICS: 'Statistics',
  CAMPAIGN: 'Campaign Management',
  BBQ_EVENT: 'Bbq Event Management',
};

export const API_RESULT = {
  FULFILLED: "fulfilled",
  REJECTED: "rejected",
  PENDING: "pending",
};

export const COMMON_MESSAGE = {
  CREATE_SUCCESS: "正常に追加されました",
  CREATE_FAILED: "新しい失敗を追加する",
  UPDATE_SUCCESS: "更新に成功",
  UPDATE_FAILED: "アップデートに失敗しました",
};

export enum AdminPermission {
  PERMISSION_MANAGEMENT = "Permission Management",
  CREATE_ADMIN = "Create Admin",
  EDIT_ADMIN = "Edit Admin",
  DELETE_ADMIN = "Delete Admin",
  CREATE_USER = "Create User",
  EDIT_USER = "Edit User",
  DELETE_USER = "Delete User",
  CREATE_PROFIT = "Create Profit",
  EDIT_PROFIT = "Edit Profit",
  DELETE_PROFIT = "Delete Profit",
  CREATE_STATISTICAL = "Create Statistical",
  EDIT_STATISTICAL = "Edit Statistical",
  DELETE_STATISTICAL = "Delete Statistical",
}

export const PermissionsArray = [
  {
    createdOnDate: "2023-01-04T04:54:53.219Z",
    createdByUserId: null,
    lastModifiedOnDate: "2023-01-04T04:54:53.219Z",
    lastModifiedByUserId: null,
    id: "a47d1ed7-fff3-4df7-99c0-864346c03825",
    name: "Create Admin",
  },
  {
    createdOnDate: "2023-01-04T04:54:53.219Z",
    createdByUserId: null,
    lastModifiedOnDate: "2023-01-04T04:54:53.219Z",
    lastModifiedByUserId: null,
    id: "349f429d-f2eb-4c5d-8291-844f6e8c5aaf",
    name: "Edit Admin",
  },
  {
    createdOnDate: "2023-01-04T04:54:53.219Z",
    createdByUserId: null,
    lastModifiedOnDate: "2023-01-04T04:54:53.219Z",
    lastModifiedByUserId: null,
    id: "0b2cf50b-2939-45f5-84e7-d79cd70300bb",
    name: "Delete Admin",
  },
  {
    createdOnDate: "2023-01-04T04:54:53.219Z",
    createdByUserId: null,
    lastModifiedOnDate: "2023-01-04T04:54:53.219Z",
    lastModifiedByUserId: null,
    id: "84393d1c-a661-40e0-98cd-3debece8b5d5",
    name: "Create User",
  },
  {
    createdOnDate: "2023-01-04T04:54:53.219Z",
    createdByUserId: null,
    lastModifiedOnDate: "2023-01-04T04:54:53.219Z",
    lastModifiedByUserId: null,
    id: "0d5b6130-d6bc-42f4-93fe-f52cb026110e",
    name: "Edit User",
  },

  {
    createdOnDate: "2023-01-04T04:54:53.219Z",
    createdByUserId: null,
    lastModifiedOnDate: "2023-01-04T04:54:53.219Z",
    lastModifiedByUserId: null,
    id: "d06cdc19-016b-4cc8-ad1b-7865f05e8f07",
    name: "Delete User",
  },
  {
    createdOnDate: "2023-01-04T04:54:53.219Z",
    createdByUserId: null,
    lastModifiedOnDate: "2023-01-04T04:54:53.219Z",
    lastModifiedByUserId: null,
    id: "1e682c23-a004-4f6a-b2ba-f94739db6fed",
    name: "Create Statistical",
  },
  {
    createdOnDate: "2023-01-04T04:54:53.219Z",
    createdByUserId: null,
    lastModifiedOnDate: "2023-01-04T04:54:53.219Z",
    lastModifiedByUserId: null,
    id: "6a4b2162-09d3-46cc-a9fc-e2426f49ea5b",
    name: "Edit Statistical",
  },
  {
    createdOnDate: "2023-01-04T04:54:53.219Z",
    createdByUserId: null,
    lastModifiedOnDate: "2023-01-04T04:54:53.219Z",
    lastModifiedByUserId: null,
    id: "ab84ac1a-b694-4201-a8f5-927d01a12194",
    name: "Delete Statistical",
  },
  {
    createdOnDate: "2023-01-04T04:54:53.219Z",
    createdByUserId: null,
    lastModifiedOnDate: "2023-01-04T04:54:53.219Z",
    lastModifiedByUserId: null,
    id: "76d11379-c375-4e7e-acf7-42dcdac5ad01",
    name: "Create Profit",
  },
  {
    createdOnDate: "2023-01-04T04:54:53.219Z",
    createdByUserId: null,
    lastModifiedOnDate: "2023-01-04T04:54:53.219Z",
    lastModifiedByUserId: null,
    id: "4e363ac8-ffff-4f9c-85b4-0d00e9126600",
    name: "Edit Profit",
  },
  {
    createdOnDate: "2023-01-04T04:54:53.219Z",
    createdByUserId: null,
    lastModifiedOnDate: "2023-01-04T04:54:53.219Z",
    lastModifiedByUserId: null,
    id: "5a3dabb0-abe8-4002-adc4-6939067e8086",
    name: "Delete Profit",
  },
  {
    createdOnDate: "2023-01-04T04:54:53.219Z",
    createdByUserId: null,
    lastModifiedOnDate: "2023-01-04T04:54:53.219Z",
    lastModifiedByUserId: null,
    id: "3762283a-4d22-469c-b7e0-cb4713a38d81",
    name: "Permission Management",
  },
];
