import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../util/api";
import { FilterDto } from "../util/dto/common.dto";
import { DEFAULT_PAGING } from "../constants/commonConst";


const initialState = {
  loading: false,
  error: "",
  message: "",
  actionResult: false,
  listCamGroup: [],
  pagination: DEFAULT_PAGING,
};

export const getCountNumberEbaySyncing = createAsyncThunk(
  "/user-token-ebay/count-ebay-syncing",
  async (_, { fulfillWithValue, rejectWithValue }) => {
    try {
      const response = await api.get("/user-token-ebay/count-ebay-syncing");
      return response.data;
    } catch (error: any) {
      const errorObj = JSON.parse(error.request.response);
      return rejectWithValue(errorObj);
    }
  }
);

export const getListAccountEbay = createAsyncThunk(
  `/user-token-ebay/list-ebay-innit-data-slow`,
  async (payload: FilterDto, { rejectWithValue }) => {
    try {
      const result = await api
        .get(`/user-token-ebay/list-ebay-innit-data-slow?page=${payload.page}&size=${payload.size}&fullTextSearch=${payload.fullTextSearch}`)
        .then((res) => res.data);
      return result;
    } catch (e) {
      console.log(e);
      rejectWithValue(e);
    }
  }
);

export const refreshAllOauthToken = createAsyncThunk(
  `/user-token-ebay/get-refresh-token-ebay`,
  async (_ , { rejectWithValue }) => {
    try {
      const result = await api
        .get(`/user-token-ebay/get-refresh-token-ebay`)
        .then((res) => res.data);
      return result;
    } catch (e) {
      console.log(e);
      rejectWithValue(e);
    }
  }
);

export const moveToFlowSlow = createAsyncThunk(
  `/move-to-flow-slow`,
  async (id: string, { rejectWithValue }) => {
    try {
      const result = await api
        .get(`/user-token-ebay/move-to-flow-slow/${id}`)
        .then((res) => res.data);
      return result;
    } catch (e) {
      console.log(e);
      rejectWithValue(e);
    }
  }
);

export const userEbay = createSlice({
  name: "userEbaySlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
  },
});

export const { } = userEbay.actions;
export default userEbay.reducer;
