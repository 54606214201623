import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { DEFAULT_PAGING } from "../constants/commonConst";
import { api } from "../util/api";

const initialState = {
  loading: false,
  listPlan : [],
  error: "",
  message: "",
  actionResult: false,
  pagination: DEFAULT_PAGING,
};

export const getListPlan = createAsyncThunk(
  "/api/plan",
  async (payload: { size: number, sort?: string, addSort?:string }, { fulfillWithValue, rejectWithValue }) => {
    try {
      const response = await api.get("/plan", {params : payload});
      return response.data;
    } catch (error: any) {
      const errorObj = JSON.parse(error.request.response);
      return rejectWithValue(errorObj);
    }
  }
);


export const planSlice = createSlice({
  name: "planSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getListPlan.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(getListPlan.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(getListPlan.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.error.message || "";
      })

  },
});

export const selectPlan = (state: any) => state.plan;
export default planSlice.reducer;
